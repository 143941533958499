@import "./styles/variables.scss";

html {
  position: fixed;
}

body {
  font-family: $font2;
  box-sizing: border-box;
  background-image: linear-gradient(
      to bottom,
      rgba(0, 0, 0, 0.805),
      rgba(0, 0, 0, 0.504),
      rgba(0, 0, 0, 0.374)
    ),
    url("https://images.unsplash.com/photo-1504148591118-0a532fa91a01?ixid=MXwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHw%3D&ixlib=rb-1.2.1&auto=format&fit=crop&w=1494&q=80");
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  margin: 0;
  width: 100vw;
  height: 100vh;
  overflow: auto;
}

p {
  font-family: $font1;
  margin: auto;
  font-size: 0.5rem;
  text-align: center;
  color: rgb(226, 226, 234);
}

h1 {
  animation: zoom 2s 0.2s ease;
  background-color: red;
  width: 1.2rem;
  color: white;
  text-align: center;
  margin: 1.5rem 0 1rem 1.5rem;
}

.footer {
  margin-top: 10px;
  text-align: center;
  font-weight: 200;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  padding: 0.4rem;
  font-family: $font1;
  text-align: center;
  margin-bottom: 0.8rem;
  text-shadow: 0.8px 1.5px 0px black;
  color: white;

  .coffeeLink {
    padding-bottom: 0.2rem;

    p {
      font-size: 1rem;
    }
  }

  a {
    transition: color 1s ease;
    font-size: 1rem;
    color: white;
    text-decoration: none;

    &:hover {
      color: rgb(97, 154, 183);
    }
  }

  .copyright {
    font-size: 0.8rem;

    a {
      font-size: 0.8rem;

      &:hover {
        color: rgb(97, 154, 183);
      }
    }
  }
}

@keyframes zoom {
  0% {
    transform: scale(1);
  }
  25% {
    transform: scale(1.15);
  }

  100% {
    transform: scale(1);
  }
}

@media screen and (min-width: 760px) {
  h1 {
    font-size: 1.5rem;
    margin-bottom: 1rem;
  }
  p {
    font-size: 1rem;
  }
}

@media screen and (min-width: 321px) and (max-width: 759px) {
  h1 {
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 1rem;
  }

  .footer {
    .coffeeLink {
      font-size: 0.6rem;
    }

    .copyright {
      font-size: 0.5rem;
      a {
        font-size: 0.5rem;
      }
    }
  }
}

@media screen and (min-width: 321px) and (max-width: 500px) {
  h1 {
    text-align: center;
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }
}

@media screen and (max-width: 320px) {
  h1 {
    font-size: 0.8rem;
    margin-bottom: 0rem;
  }

  .footer {
    .coffeeLink {
      font-size: 0.6rem;
    }

    .copyright {
      font-size: 0.4rem;
      a {
        font-size: 0.4rem;
      }
    }
  }
}
