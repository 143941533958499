@import "../styles/variables.scss";

.btn {
  font-family: $font1;
  font-weight: 200;
  font-size: 1.8rem;
  padding: 10px;
  border-radius: 2rem;
  background-color: rgba(255, 255, 255, 0.798);
  border: 2px solid black;
  box-shadow: rgba(0, 0, 0, 0.401) 2px 4px 3px 0px;
  color: black;
  z-index: 999;

  &:hover {
    cursor: pointer;
    transform: scale(1.03);
    opacity: 0.95;
  }

  &--selected {
    background-color: rgba(28, 95, 129, 0.819);
    color: white;

    &:hover {
      cursor: pointer;
    }
  }

  &:focus {
    outline: none;
  }
}

@media screen and (min-width: 700px) {
  .btn {
    font-size: 2rem;
    height: 6rem;
    padding: 15px;
  }
}

@media screen and (min-width: 475px) and (max-width: 699px) {
  .btn {
    font-size: 1.4rem;
    height: 4rem;
    width: 140px;
  }
}

@media screen and (min-width: 321px) and (max-width: 474px) {
  .btn {
    font-size: 1rem;
    width: 120px;
  }
}
@media screen and (max-width: 320px) {
  .btn {
    font-size: 0.8rem;
    width: 120px;
  }
}

@media screen and (min-width: 1101px) {
  .btn {
    font-size: 2.5rem;
    height: 6.5rem;
    width: 200px;
  }
}
