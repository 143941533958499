@import "../styles/variables.scss";

.grid-container {
  display: flex;
  justify-content: center;
  width: 90vw;
  margin: 2rem auto;
}
.btn-grid {
  display: grid;
  justify-content: center;
  grid-template-columns: auto auto;
  grid-template-rows: auto auto auto auto;
  row-gap: 30px;
  column-gap: 30px;
}

@media screen and (min-width: 870px) {
  .btn-grid {
    grid-template-columns: 175px 175px 175px 175px;
    grid-template-rows: auto;
    column-gap: 60px;
    row-gap: 60px;
  }

  .grid-container {
    padding: 20px;
    margin: auto;
  }
}

@media screen and (min-width: 710px) and (max-width: 869px) {
  .btn-grid {
    grid-template-columns: 150px 150px 150px 150px;
    grid-template-rows: auto;
    column-gap: 35px;
    row-gap: 35;
  }

  .grid-container {
    padding: 20px;
    margin: auto;
  }
}

@media screen and (min-width: 400px) and (max-width: 709px) {
  .btn-grid {
    column-gap: 25px;
    row-gap: 20px;
  }
}

@media screen and (min-width: 321px) and (max-width: 399px) {
  .btn-grid {
    column-gap: 25px;
    row-gap: 12px;
  }
}
@media screen and (max-width: 320px) {
  .btn-grid {
    column-gap: 28px;
    row-gap: 12px;
  }
}
